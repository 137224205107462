<template>
  <div class="academic-years">
    <lenon-list-page
      title="School Academic Years"
      :columns="columns"
      :rows="academicYears"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-details="true"
      search-placeholder="Type name + Enter"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start mb-2 desktop-only">
<!--          <lenon-button-->
<!--            v-responsive="$store.getters['app/largeScreen']"-->
<!--            variant="outline-primary"-->
<!--            class="mr-2"-->
<!--            icon="SettingsIcon"-->
<!--            label="Change Ac. Year"-->
<!--            tool-tip-text="Change default academic year and term"-->
<!--            @onClick="showDefaultAyModal()"-->
<!--          />-->
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            variant="outline-primary"
            class="mr-2"
            icon="SettingsIcon"
            label="Classes"
            tool-tip-text="Manage Classes (CRUD)"
            @onClick="showClassModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            variant="outline-primary"
            icon="SettingsIcon"
            class="mr-2"
            label="Terms"
            tool-tip-text="Manage Terms (CRUD)"
            @onClick="showTermModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            variant="outline-primary"
            icon="SettingsIcon"
            class="mr-2"
            label="Tags"
            tool-tip-text="Manage Tags (CRUD)"
            @onClick="showTagsModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            icon="SettingsIcon"
            label="Academic Years"
            tool-tip-text="Manage Academic Years"
            class=""
            @onClick="showAcademicYearModal()"
          />
        </div>
        <div v-responsive="$store.getters['app/smallScreen']">
          <lenon-dropdown left>
<!--            <lenon-dropdown-item-->
<!--              icon="SettingsIcon"-->
<!--              text="Change Ac. Year"-->
<!--              @click="showDefaultAyModal()"-->
<!--            />-->
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Manage Classes"
              @click="showClassModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Manage Terms"
              @click="showTermModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Manage Tags"
              @click="showTagsModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Academic Years"
              @click="showAcademicYearModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #row-details="{row}">
        <b-card>
          <b-row
            v-for="(term,i) in row.item.terms"
            :key="i"
            class="mb-2"
          >
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Term : </strong><span class="font-weight-bold">{{ term.term_name }}</span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Starts : </strong><span class="font-weight-bold">{{ term.formatted_term_start_date }}</span>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Ends : </strong><span class="font-weight-bold">{{ term.formatted_term_end_date }}</span>
            </b-col>

            <b-col
              md="12"
              class="mb-1"
            >
              <div class="row">
                <div class="col-4">
                  <strong>Vacation Date : </strong><span
                    class="font-weight-bold"
                  >{{ term.formatted_vacation_date }}</span>
                </div>
                <div class="col-4">
                  <strong>Next Term Reopening Date : </strong><span
                    class="font-weight-bold"
                  >{{ term.formatted_next_term_reopening_date }}</span>
                </div>
              </div>
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <strong>Midterms </strong>
              <b-row
                v-if="term.midterms.length"
                class="px-1"
              >
                <div
                  v-for="(mt,index) in term.midterms"
                  :key="index"
                  style="margin-bottom: 5px; margin-right: 3px;"
                >
                  <b-badge variant="light-success">
                    {{ mt.title }} ({{ mt.start_date }} to {{ mt.end_date }})
                  </b-badge>
                </div>
              </b-row>
              <b-row
                v-else
                class="px-1"
              >
                <b-badge variant="light-success">
                  <strong>N/A</strong>
                </b-badge>

              </b-row>
            </b-col>
            <div class="full-width border-bottom border-gray" />
          </b-row>
        </b-card>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #completed="{row}">
        <div
          v-if="+row.item.current===1"
        >
          <lenon-button
            :tool-tip-text="row.item.completed?'Reverse Completion.':'Complete Academic year.'"
            :variant="row.item.completed?'outline-danger':'outline-success'"
            :icon="row.item.completed?'RepeatIcon':'CheckCircleIcon'"
            :icon-only="true"
            @onClick="showConfirm(row.item)"
          />
          <lenon-button
            tool-tip-text="Verify students were correctly moved to respective classes"
            icon="UsersIcon"
            :icon-only="true"
            variant="flat-primary"
            @onClick="$router.push({name:'registration'})"
          />
        </div>
        <b-badge
          v-else
          v-b-tooltip="'This option will be enabled at the appropriate time'"
          variant="light-success"
        >
          N/A
        </b-badge>
      </template>
      <template #current="{row}">
        <b-badge :variant="getStatusVariant(row.item.current)">
          {{ getStatusText(row.item.current) }}
        </b-badge>
      </template>
      <template #actions="{row}">
        <lenon-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>
    <school-class-setup
      :modal-opened="schoolClassModalOpened"
      @modalClosed="closeModal('schoolClass')"
    />
    <academic-year-setup
      :modal-opened="academicYearModalOpened"
      @modalClosed="closeModal('academicYear')"
    />
    <term-setup
      :modal-opened="termModalOpened"
      @modalClosed="closeModal('term')"
    />
    <tags-setup
      :modal-opened="tagsModalOpened"
      @modalClosed="closeModal('tags')"
    />
    <default-academic-year
      :modal-opened="defaultAyModalOpened"
      @modalClosed="closeModal('defaultAy')"
    />
    <lenon-modal
      :title="academicYear.completed?'Confirm Academic Year Reversal':'Confirm Academic Year Completion'"
      :show="confirmAyCompletionModal"
      :show-overlay="completing"
      :ok-text="academicYear.completed?'Ok, reverse':'Ok, complete'"
      cancel-text="Cancel"
      @onClose="(e)=>{confirmAyCompletionModal=false}"
      @onOk="handleOk()"
    >
      <div v-if="academicYear.completed">
        You are about to roll back the completion of the current <b>Academic Year ({{ academicYear.name }})</b>.
        All advanced students will be reverted and moved back to their original classes.
        Any created batches will be maintained and reused when needed.
      </div>
      <div v-else>
        You are about to complete the current <b>Academic Year ({{ academicYear.name }})</b>.
        All promoted students will be advanced to the next class according to your school's class promotion flow.
        Additionally, a batch will be created for final year students. <br> <br>
      </div>
      <strong
        v-if="!academicYear.completed"
        class="text-success"
      >NB: This operation can be reversed.</strong> <br><br>
      <strong class="text-danger">NB: All activities on this account will be blocked until the process completes.</strong>
      <br><br>
      <strong>If you are running concurrent terms and only want to move students from active classes in the current term, check this option.</strong>
      <br><br>
      <b-form-checkbox v-model="currentTerm">
        Only move students in current term
      </b-form-checkbox>
    </lenon-modal>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import SchoolClassSetup from '@/views/admin/academics/terms-and-classes/SchoolClassSetup.vue'
import TagsSetup from '@/views/admin/academics/terms-and-classes/TagsSetup.vue'
import AcademicYearSetup from '@/views/admin/academics/terms-and-classes/AcademicYearSetup.vue'
import TermSetup from '@/views/admin/academics/terms-and-classes/TermSetup.vue'
import DefaultAcademicYear from '@/views/admin/academics/terms-and-classes/DefaultAcademicYear.vue'
import { TERMS_CLASSES_TEACHERS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import { COMPLETE_ACADEMIC_YEAR_M } from '@/graphql/mutations'
import { formatDate } from '@/@core/utils/filter'
import LenonModal from '@/lenon/components/LenonModal'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'

export default {
  name: 'AcademicYearList',
  components: {
    LenonDropdownItem,
    LenonDropdown,
    LenonModal,
    LenonListPage,
    TermSetup,
    AcademicYearSetup,
    SchoolClassSetup,
    DefaultAcademicYear,
    TagsSetup,
    LenonButton,
    BCard,
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast],
  data() {
    return {
      schoolClassModalOpened: false,
      academicYearModalOpened: false,
      termModalOpened: false,
      defaultAyModalOpened: false,
      confirmAyCompletionModal: false,
      tagsModalOpened: false,
      tableLoading: false,
      completing: false,
      currentTerm: false,
      academicYear: {},
    }
  },
  computed: {
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    columns() {
      const show = this.$store.getters['app/isLargeScreen']
      return [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: 'Academic Year',
          sortable: true,
        },
        {
          key: 'formatted_start_date',
          label: 'Starts',
          sortable: show,
        },
        {
          key: 'formatted_end_date',
          label: 'Ends',
          sortable: show,
        },
        {
          key: 'current',
          label: 'Status',
          sortable: show,
        },
        {
          key: 'completed',
          label: 'COMPLETE',
          sortable: show,
        },
        {
          key: 'actions',
          label: 'Details',
        },
      ]
    },
  },
  mounted() {
    const thisYear = new Date().getFullYear()
    const nextYear = new Date().getFullYear() + 1
    this.academicYearName = `${thisYear}/${nextYear}`
    this.fetchClassesTermsAndTeachers()
  },
  methods: {
    handleOk() {
      this.completeAcademicYear(this.academicYear)
    },
    showConfirm(ay) {
      this.academicYear = ay
      this.confirmAyCompletionModal = true
    },
    completeAcademicYear(ay) {
      this.completing = true
      this.$apollo.mutate({
        mutation: COMPLETE_ACADEMIC_YEAR_M,
        variables: {
          reverse: ay.completed,
          academic_year_id: ay.id,
          current_term: this.currentTerm,
        },
      }).then(res => {
        if (res.data && res.data?.completeAcademicYear) {
          ay.completed = !ay.completed
          this.showSuccess(ay.completed ? 'Academic Year Completed Successfully' : 'Academic Year Completion Reversed Successfully')
          this.confirmAyCompletionModal = false
        } else {
          this.showError('Something went wrong, failed to initiate completion process')
        }
      }).finally(() => {
        this.completing = false
      })
    },
    getStatusText(status) {
      let text = 'Pending'
      switch (+status) {
        case 1:
          text = 'In progress'
          break
        case 0:
          text = 'Ended'
          break
      }
      return text
    },
    getStatusVariant(status) {
      let variant = 'light-warning'
      switch (+status) {
        case 1:
          variant = 'light-success'
          break
        case 0:
          variant = 'light-danger'
          break
      }
      return variant
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'term':
          this.termModalOpened = false
          break
        case 'tags':
          this.tagsModalOpened = false
          break
        case 'academicYear':
          this.academicYearModalOpened = false
          break
        case 'schoolClass':
          this.schoolClassModalOpened = false
          break
        case 'defaultAy':
          this.defaultAyModalOpened = false
      }
    },
    showTermModal() {
      this.termModalOpened = true
    },
    showTagsModal() {
      this.tagsModalOpened = true
    },
    showAcademicYearModal() {
      this.academicYearModalOpened = true
    },
    showClassModal() {
      this.schoolClassModalOpened = true
    },
    showDefaultAyModal() {
      this.defaultAyModalOpened = true
    },
    fetchClassesTermsAndTeachers() {
      this.$apollo.query({ query: TERMS_CLASSES_TEACHERS_Q })
        .then(res => {
          this.$store.commit('termsClasses/setClassesTermsAndTeachers', res.data)
        })
    },
  },
}
</script>
