export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}
export function missingClassText(subject) {
  return `<small><b>If a class does not show up here, then you previously included it in other ${subject} you created, you have to remove it from the other ${subject} if you want to create a new one for the class in question.</b></small>`
}
