<template>
  <div class="">
    <!--    Class Form-->
    <validation-observer
      ref="classForm"
      v-slot="{invalid}"
    >
      <lenon-modal
        :title="`${updateClassMode?'Update':'Create'} Class`"
        :show="classModalOpened"
        :show-overlay="deletingClass"
        size="md"
        @onClose="closeClassModal()"
      >
        <b-row class="align-items-start">
          <div class="col-12 mb-1">
            <small>NB: Creating or Deleting a class will automatically update promotion flow. Always verify promotion flow after performing any of these actions.</small>
          </div>
          <div class="col-7">
            <lenon-select
              v-model="selectedClassId"
              :options="classes"
              label-name="name"
              value-name="id"
              placeholder="All Classes"
              @input="populateClassesForm"
            />
            <div v-if="teacher.name">
              <strong>Class Teacher :</strong> <label>{{ teacher.name }}</label>
            </div>
          </div>
          <div class="col-2">
            <lenon-dropdown
              icon="TrashIcon"
            >
              <b-dropdown-item @click="deleteClass()">
                Yes
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item>
                No
              </b-dropdown-item>
            </lenon-dropdown>
          </div>
          <div class="col-2">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetClassForm()"
            />
          </div>
        </b-row>
        <error-display :error="error" />
        <lenon-input
          v-model="className"
          name="name"
          placeholder="Enter class name"
          rules="required"
          label="Class Name"
        />
        <div class="d-flex">
          <lenon-file-button
            variant="outline-primary"
            label="Upload Cover Photo"
            class="mr-1"
            @onFileUpload="handleFileUpload"
          />
          <template v-if="coverPreview">
            <b-avatar
              :src="coverPreview"
              size="md"
              class="mr-1"
            />
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              :icon-only="true"
              @onClick="removeCover()"
            />
          </template>
        </div>
        <div style="margin-top: 30px;">
          <lenon-select
            v-model="teacherId"
            placeholder="Select Class Teacher"
            label-name="fullName"
            value-name="id"
            :options="teachers"
            label="Class Teacher"
          />
        </div>
        <div class="row">
          <div class="col-12 mt-1">
            <strong>Did you add a new class?, you can update the order of your classes below.</strong>
          </div>
          <div class="col-12 mt-1">
            <lenon-button
              variant="outline-primary"
              icon="RepeatIcon"
              label="Reorder Classes"
              @onClick="showPromotionFlowModal()"
            />
          </div>
        </div>
        <template slot="modal-actions">
          <b-row class="float-right">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeClassModal()"
            />
            <lenon-button
              :label="updateClassMode?'Update':'Create'"
              :disabled="invalid"
              :loading="classLoading"
              loading-text="Loading..."
              @onClick="updateClassMode?updateClass():createClass()"
            />
          </b-row>
        </template>
      </lenon-modal>
    </validation-observer>
    <reorder-class
      :modal-opened="promotionFlowModalOpened"
      @modalClosed="closeModal('promotion')"
    />
  </div>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_CLASS_M, DELETE_CLASS_M, UPDATE_CLASS_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import LenonFileButton from '@/lenon/components/LenonFileButton'
import ReorderClass from '@/views/admin/academics/terms-and-classes/ReorderClass.vue'

export default {
  name: 'SchoolClassSetup',
  components: {
    LenonFileButton,
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ReorderClass,

  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      classModalOpened: false,
      updateClassMode: false,
      classLoading: false,
      className: '',
      selectedClassId: null,

      deletingClass: false,

      teacherId: null,
      uploadedCover: null,
      coverPreview: null,
      remove: false,
      error: {},
      promotionFlowModalOpened: false,
    }
  },
  computed: {
    selectedClass() {
      return this.classes.find(cl => cl.id === this.selectedClassId)
    },
    teacher() {
      const teacher = { id: null, name: null }
      teacher.id = this.selectedClass?.class_teacher_id || null
      teacher.name = this.selectedClass?.teacher_name || null
      return teacher
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    teachers() {
      return this.$store.getters['termsClasses/teachers']
    },
  },
  watch: {
    modalOpened(opened) {
      this.classModalOpened = opened
    },
  },
  methods: {
    showPromotionFlowModal() {
      this.promotionFlowModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'promotion':
          this.promotionFlowModalOpened = false
      }
    },
    removeCover() {
      this.uploadedCover = null
      this.coverPreview = null
      this.remove = true
    },
    handleFileUpload(file) {
      this.uploadedCover = file.file
      this.coverPreview = file.dataUrl
      this.remove = false
    },
    resetClassForm() {
      this.className = ''
      this.teacherId = null
      this.selectedClassId = null
      this.updateClassMode = false
      this.coverPreview = null
      this.$refs.classForm.reset()
    },
    populateClassesForm(cls) {
      if (this.selectedClass) {
        this.updateClassMode = true
        this.className = this.selectedClass.name
        this.coverPreview = this.selectedClass.cover ? `${process.env.VUE_APP_STORAGE_PATH}/${this.selectedClass.cover}` : null
        this.teacherId = this.teacher.id
      } else {
        this.resetClassForm()
      }
    },
    closeClassModal() {
      this.classModalOpened = false
      this.$emit('modalClosed')
    },
    updateClass() {
      this.error = {}
      if (!this.selectedClassId) {
        return
      }
      this.classLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_CLASS_M,
        variables: {
          input: {
            id: this.selectedClassId, name: this.className, class_teacher_id: this.teacherId, cover: this.uploadedCover, remove: this.remove,
          },
        },
      }).then(res => {
        this.showSuccess('Updated class successfully')
        this.classLoading = false
        this.$store.commit('termsClasses/updateClass', res.data.updateClass)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update Class')
        this.classLoading = false
      })
    },
    createClass() {
      this.error = {}
      this.classLoading = true
      this.$apollo.mutate({
        mutation: CREATE_CLASS_M,
        variables: {
          input: {
            name: this.className, class_teacher_id: this.teacherId, cover: this.uploadedCover, remove: this.remove,
          },
        },
      }).then(res => {
        this.showSuccess('Created class successfully')
        this.classLoading = false
        this.$store.commit('termsClasses/addClass', res.data.createClass)
        this.resetClassForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.classLoading = false
      })
    },
    deleteClass() {
      if (!this.selectedClass) {
        this.showInfo('Please select a class ')
        return
      }
      this.deletingClass = true
      this.$apollo.mutate({ mutation: DELETE_CLASS_M, variables: { id: this.selectedClassId } }).then(() => {
        this.deletingClass = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('termsClasses/removeClass', this.selectedClassId)
        this.resetClassForm()
      }).catch(() => {
        this.deletingClass = false
      })
    },
  },
}
</script>
