<template>
  <lenon-modal
    title="Reorder Classes"
    :show="classPositionModalOpened"
    @onClose="closeGradeModal()"
  >
    <!--    <div class="mb-1">-->
    <!--      <small>-->
    <!--        <b>-->
    <!--          This shows the order in which students will be promoted from one class to another, you can change by dragging and dropping.-->
    <!--        </b>-->
    <!--      </small>-->
    <!--    </div>-->
    <Container
      class="list-group list-group-flush cursor-move"
      @drop="onDrop"
    >
      <Draggable
        v-for="(singleClass,index) in selectedClasses"
        :key="singleClass.id"
      >
        <b-list-group-item>
          <div class="d-flex">
            <b-avatar
              :src="getFullPath(singleClass.cover)"
              :variant="getVariant(index)"
              :text="getFullPath(singleClass.cover)?null:`${index+1}`"
            />
            <div class="ml-25">
              <b-card-text class="font-weight-bold mb-0">
                {{ singleClass.name }}
              </b-card-text>
              <small>Class Teacher : {{ singleClass.teacher_name || 'None' }}</small>
            </div>
          </div>
        </b-list-group-item>
      </Draggable>
    </Container>
    <template slot="modal-actions">
      <b-row class="float-right">
        <lenon-button
          variant="flat-danger"
          icon="XIcon"
          class="mr-1"
          label="Cancel"
          @onClick="closeGradeModal()"
        />
        <lenon-button
          label="Save"
          :loading="classPositionsLoading"
          loading-text="Loading..."
          @onClick="save()"
        />
      </b-row>
    </template>
  </lenon-modal>
</template>

<script>

import {
  BRow, BListGroupItem, BCardText, BAvatar,
} from 'bootstrap-vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
// import draggable from 'vuedraggable'
import { UPDATE_CLASS_POSITIONS_M } from '@/graphql/mutations'
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '@/libs/dnd'

export default {
  name: 'ReorderClass',
  components: {
    LenonButton,
    LenonModal,
    BRow,
    // draggable,
    BListGroupItem,
    BAvatar,
    BCardText,
    Container,
    Draggable,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      classPositionModalOpened: false,
      classPositionsLoading: false,
      variants: [
        'primary',
        'warning',
        'light-success',
        'light-warning',
        'light-primary',
        'success',
      ],
      selectedClasses: [],
    }
  },
  computed: {
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
  },
  watch: {
    classes(val) {
      this.selectedClasses = val
    },
    modalOpened(opened) {
      this.classPositionModalOpened = opened
    },
  },
  mounted() {
    this.selectedClasses = this.classes
  },
  methods: {
    getFullPath(path) {
      if (path) {
        return `${process.env.VUE_APP_STORAGE_PATH}/${path}`
      }
      return null
    },
    onDrop(dropResult) {
      this.selectedClasses = applyDrag(this.selectedClasses, dropResult)
    },
    getVariant(index) {
      const v = index % this.variants.length
      return this.variants[v]
    },
    save() {
      this.classPositionsLoading = true
      const classPositions = this.selectedClasses.map((c, index) => ({
        id: c.id,
        position: index,
      }))
      this.$apollo.mutate({
        mutation: UPDATE_CLASS_POSITIONS_M,
        variables: {
          input: classPositions,
        },
      }).then(res => {
        this.classPositionsLoading = false
        this.showSuccess('Updated Successfully')
        this.$store.commit('termsClasses/setClasses', { classes: this.selectedClasses })
      }).catch(err => {
        this.classPositionsLoading = false
        this.showError('Failed to update class positions')
      })
    },
    closeGradeModal() {
      this.classPositionModalOpened = false
      this.$emit('modalClosed')
    },
  },
}
</script>
