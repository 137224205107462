<template>
  <!--    Term Form-->
  <validation-observer
    ref="defaultForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      title="Change Default Academic Year & Term"
      :show="defaultAyModalOpened"
      @onClose="closeDefaultAyModal()"
    >
      <div
        v-if="isEnabled"
        class="mb-2"
      >
        <strong>
          <em>Selected users will be limited to using the specified academic year and term settings.</em>
        </strong>
      </div>
      <lenon-select
        v-model="selectedAcademicYearId"
        placeholder="All Academic Years"
        label="Academic Year"
        :options="academicYears"
        label-name="name"
        value-name="id"
        rules="required"
      />
      <lenon-select
        v-model="selectedTermId"
        placeholder="All Terms"
        label="Term"
        :options="filteredTerms"
        label-name="term_name"
        value-name="term_id"
        rules="required"
      />
      <lenon-multi-select
        v-model="selectedUsers"
        placeholder="All Users"
        label="Affected Users"
        :options="users"
        label-name="full_name"
        value-name="id"
        rules="required"
      />
      <div class="mb-5" />
      <div
        v-if="isEnabled"
        class="row"
      >
        <div class="col-12">
          <lenon-button
            variant="outline-success"
            icon="RepeatIcon"
            class="mr-1"
            label="Reset to Current Academic Year"
            @onClick="reset()"
          />
        </div>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeDefaultAyModal()"
          />
          <lenon-button
            label="Update"
            :disabled="invalid"
            :loading="defaultAyLoading"
            loading-text="Loading..."
            @onClick="save()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { UPDATE_DEFAULT_ACADEMIC_YEAR_M, RESET_TO_CURRENT_AY_M } from '@/graphql/mutations'
import { GET_CURRENT_AY_Q } from '@/graphql/queries'
import logData from '@/libs/log'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'

export default {
  name: 'TermSetup',
  components: {
    LenonMultiSelect,
    LenonSelect,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAcademicYearId: null,
      selectedTermId: null,
      defaultAyModalOpened: false,
      defaultAyLoading: false,
      selectedUsers: [],
    }
  },
  computed: {
    defaultAy() {
      if (this.isEnabled) {
        return this.$store.getters['termsClasses/defaultAy']
      }
      return null
    },
    isEnabled() {
      return this.$store.getters['termsClasses/isEnabled']
    },
    filteredTerms() {
      const ay = this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
      if (ay) {
        return ay.terms
      }
      return []
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    users() {
      const users = this.$store.getters['accountUsers/users']
      if (users) {
        return users.map(usr => ({ ...usr, full_name: `${usr.first_name} ${usr.last_name}` }))
      }
      return []
    },
  },
  watch: {
    modalOpened(opened) {
      this.defaultAyModalOpened = opened
    },
    defaultAy(val) {
      if (val) {
        this.selectedAcademicYearId = val.academic_year_id
        this.selectedTermId = val.term_id
        this.selectedUsers = val.users.map(c => this.users.find(usr => +usr.id === +c))
      }
    },
  },
  mounted() {
    if (this.defaultAy) {
      this.selectedAcademicYearId = this.defaultAy.academic_year_id
      this.selectedTermId = this.defaultAy.term_id
      this.selectedUsers = this.defaultAy.users.map(c => this.users.find(usr => +usr.id === +c))
    }
  },
  methods: {
    closeDefaultAyModal() {
      this.defaultAyModalOpened = false
      this.$emit('modalClosed')
    },
    reset() {
      this.defaultAyLoading = true
      this.$apollo.mutate({ mutation: RESET_TO_CURRENT_AY_M })
        .then(res => {
          this.showSuccess('Reset successfully')
          this.defaultAyLoading = false
          this.selectedAcademicYearId = null
          this.selectedTermId = null
          this.selectedUsers = []
          this.$store.commit('termsClasses/setDefaultAy', {})
          this.$store.commit('auth/updateCurrentAcademicYear', res.data.resetToCurrentAy)
          this.$refs.defaultForm.reset()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to reset')
          this.defaultAyLoading = false
        })
    },
    save() {
      if (!this.selectedTermId || !this.selectedAcademicYearId || !this.selectedUsers) {
        return
      }
      this.defaultAyLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_DEFAULT_ACADEMIC_YEAR_M,
        variables: {
          academic_year_id: this.selectedAcademicYearId,
          term_id: this.selectedTermId,
          users: this.selectedUsers.map(usr => usr.id),
        },
      }).then(res => {
        this.showSuccess('Updated successfully')
        this.defaultAyLoading = false
        this.$store.commit('termsClasses/setDefaultAy', res.data.updateDefaultAy.default_academic_year)
        this.$store.commit('auth/updateCurrentAcademicYear', res.data.updateDefaultAy.current_academic_year)
      })
        .catch(err => {
          logData(err)
          this.error = err
          this.defaultAyLoading = false
          this.showError('Failed to update')
        })
    },

  },
}
</script>
