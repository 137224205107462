<template>
  <!--    Tag Form-->
  <validation-observer
    ref="tagForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateTagMode?'Update':'Create'} Tag`"
      :show="tagModalOpened"
      :show-overlay="deletingTag"
      @onClose="closeTagModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedTagId"
            placeholder="All Tags"
            :options="tags"
            label-name="name"
            value-name="id"
            @input="populateTagForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteTag()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetTagForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="tagName"
        name="name"
        placeholder="Enter tag name"
        rules="required"
        label="Tag Name"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeTagModal()"
          />
          <lenon-button
            :label="updateTagMode?'Update':'Create'"
            :disabled="invalid"
            :loading="tagLoading"
            loading-text="Loading..."
            @onClick="updateTagMode?updateTag():createTag()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_TAG_M, DELETE_TAG_M, UPDATE_TAG_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'TagsSetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      tagModalOpened: false,
      updateTagMode: false,
      tagLoading: false,
      deletingTag: false,
      selectedTagId: null,
      tagName: '',
    }
  },
  computed: {
    selectedTag() {
      return this.tags.find(ter => ter.id === this.selectedTagId)
    },
    tags() {
      return this.$store.getters['termsClasses/tags']
    },
  },
  watch: {
    modalOpened(opened) {
      this.tagModalOpened = opened
    },
  },
  methods: {
    // tag
    resetTagForm() {
      this.tagName = ''
      this.selectedTagId = null
      this.updateTagMode = false
      this.$refs.tagForm.reset()
    },
    populateTagForm(tag) {
      if (this.selectedTag) {
        this.updateTagMode = true
        this.tagName = this.selectedTag?.name
      } else {
        this.resetTagForm()
      }
    },
    closeTagModal() {
      this.tagModalOpened = false
      this.$emit('modalClosed')
    },
    updateTag() {
      this.error = {}
      if (!this.selectedTagId) {
        return
      }
      this.tagLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_TAG_M,
        variables: { input: { id: this.selectedTagId, name: this.tagName } },
      }).then(res => {
        this.showSuccess('Updated tag successfully')
        this.tagLoading = false
        this.$store.commit('termsClasses/updateTag', res.data.updateTag)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update tag')
        this.tagLoading = false
      })
    },
    createTag() {
      this.error = {}
      this.tagLoading = true
      this.$apollo.mutate({
        mutation: CREATE_TAG_M,
        variables: { input: { name: this.tagName } },
      }).then(res => {
        this.showSuccess('Created tag successfully')
        this.tagLoading = false
        this.$store.commit('termsClasses/addTag', res.data.createTag)
        this.resetTagForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.tagLoading = false
      })
    },
    deleteTag() {
      if (!this.selectedTagId) {
        this.showInfo('Please select a tag')
        return
      }
      this.deletingTag = true
      this.$apollo.mutate({ mutation: DELETE_TAG_M, variables: { id: this.selectedTagId } }).then(() => {
        this.deletingTag = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('termsClasses/removeTag', this.selectedTagId)
        this.selectedTagId = null
        this.resetTagForm()
      }).catch(() => {
        this.deletingTag = false
      })
    },

  },
}
</script>
