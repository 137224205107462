<template>
  <!--    Term Form-->
  <validation-observer
    ref="termForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateTermMode?'Update':'Create'} Term`"
      :show="termModalOpened"
      :show-overlay="deletingTerm"
      @onClose="closeTermModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedTermId"
            placeholder="All Terms"
            :options="terms"
            label-name="name"
            value-name="id"
            @input="populateTermForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteTerm()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetTermForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="termName"
        name="name"
        placeholder="Enter term name"
        rules="required"
        label="Term Name"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeTermModal()"
          />
          <lenon-button
            :label="updateTermMode?'Update':'Create'"
            :disabled="invalid"
            :loading="termLoading"
            loading-text="Loading..."
            @onClick="updateTermMode?updateTerm():createTerm()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_TERM_M, DELETE_TERM_M, UPDATE_TERM_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'TermSetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      termModalOpened: false,
      updateTermMode: false,
      termLoading: false,
      deletingTerm: false,
      selectedTermId: null,
      termName: '',
    }
  },
  computed: {
    selectedTerm() {
      return this.terms.find(ter => ter.id === this.selectedTermId)
    },
    terms() {
      return this.$store.getters['termsClasses/schoolTerms']
    },
  },
  watch: {
    modalOpened(opened) {
      this.termModalOpened = opened
    },
  },
  methods: {
    // term
    resetTermForm() {
      this.termName = ''
      this.selectedTermId = null
      this.updateTermMode = false
      this.$refs.termForm.reset()
    },
    populateTermForm(term) {
      if (this.selectedTerm) {
        this.updateTermMode = true
        this.termName = this.selectedTerm?.name
      } else {
        this.resetTermForm()
      }
    },
    closeTermModal() {
      this.termModalOpened = false
      this.$emit('modalClosed')
    },
    updateTerm() {
      this.error = {}
      if (!this.selectedTermId) {
        return
      }
      this.termLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_TERM_M,
        variables: { input: { id: this.selectedTermId, name: this.termName } },
      }).then(res => {
        this.showSuccess('Updated term successfully')
        this.termLoading = false
        this.$store.commit('termsClasses/updateTerm', res.data.updateTerm)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update term')
        this.termLoading = false
      })
    },
    createTerm() {
      this.error = {}
      this.termLoading = true
      this.$apollo.mutate({
        mutation: CREATE_TERM_M,
        variables: { input: { name: this.termName } },
      }).then(res => {
        this.showSuccess('Created term successfully')
        this.termLoading = false
        this.$store.commit('termsClasses/addTerm', res.data.createTerm)
        this.resetTermForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.termLoading = false
      })
    },
    deleteTerm() {
      if (!this.selectedTermId) {
        this.showInfo('Please select a term')
        return
      }
      this.deletingTerm = true
      this.$apollo.mutate({ mutation: DELETE_TERM_M, variables: { id: this.selectedTermId } }).then(() => {
        this.deletingTerm = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('termsClasses/removeTerm', this.selectedTermId)
        this.selectedTermId = null
        this.resetTermForm()
      }).catch(() => {
        this.deletingTerm = false
      })
    },

  },
}
</script>
