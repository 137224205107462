<template>
  <!--    Academic Year-->
  <div class="">
    <validation-observer
      ref="academicYearForm"
      v-slot="{invalid}"
    >
      <lenon-modal
        :title="updateAcademicYearMode?`Update Academic Year`:'Create Academic Year'"
        :show="academicYearModalOpened"
        size="lg"
        :show-overlay="academicYearLoading"
        @onClose="closeAcademicYearModal()"
      >
        <!--      <p>Please enter the name of the academic year and provide the <strong>start-->
        <!--        and end-->
        <!--        date</strong>, then add the various <strong>terms</strong> and their respective <strong>classes</strong>.-->
        <!--        You can also <b-link variant="danger" @click="clearForm()">Clear this Form</b-link> if you are setting up a new academic year.-->
        <!--      </p>-->
        <b-row class="align-items-start mb-1">
          <div class="col-7">
            <lenon-select
              v-model="selectedAcademicYearId"
              :options="academicYears"
              label-name="name"
              value-name="id"
              placeholder="All Academic Years"
              @input="populateAcademicYearForm"
            />
          </div>
          <div class="col-2">
            <lenon-dropdown
              icon="TrashIcon"
            >
              <b-dropdown-item @click="deleteAcademicYear()">
                Yes
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item>
                No
              </b-dropdown-item>
            </lenon-dropdown>
          </div>
          <div class="col-2">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="clearForm()"
            />
          </div>
        </b-row>
        <error-display :error="error" />
        <b-row>
          <div class="col-md-4">
            <lenon-input
              v-model="academicYear.name"
              name="name"
              rules="required|valid_academic_year"
              label="Name"
              :placeholder="`eg. ${currentAyear}`"
            />
          </div>
          <div class="col-md-4">
            <lenon-date-picker
              v-model="academicYear.start_date"
              label="Start Date"
              name="start_date"
              rules="required"
            />
          </div>
          <div class="col-md-4">
            <lenon-date-picker
              v-model="academicYear.end_date"
              label="End Date"
              name="end_date"
              rules="required"
            />
          </div>
        </b-row>
        <Container
          v-if="$store.getters['app/isLargeScreen']"
          class="list-group list-group-flush cursor-move"
          @drop="onDrop"
        >
          <Draggable
            v-for="(term,i) in academicYear.terms"
            id="lenon_draggable"
            :key="`term-${i}`"
          >
            <b-list-group-item>
              <div class="mt-1">
                <strong>Term {{ i + 1 }}</strong>
              </div>
              <b-row>
                <div class="col-md-4">
                  <lenon-select
                    v-model="term.term_id"
                    :options="terms"
                    label-name="name"
                    value-name="id"
                    label="Name"
                    name="term"
                    rules="required"
                  />
                </div>
                <div class="col-md-4">
                  <lenon-date-picker
                    v-model="term.term_start_date"
                    label="Start Date"
                    :name="`term_start_date`"
                    rules="required"
                    :show-lable="false"
                  />
                </div>
                <div class="col-md-4">
                  <lenon-date-picker
                    v-model="term.vacation_date"
                    label="Vacation Date"
                    :name="`vacation.${i}`"
                    :show-lable="false"
                    rules="required"
                  />
                </div>
                <div class="col-12 mt-2">
                  <label>School Breaks</label>
                  <div class="row">
                    <div class="col-md-10">
                      <lenon-multi-select
                        v-model="term.midterms"
                        :options="term.midterms"
                        label-name="title"
                        placeholder="Break Title"
                        @remove="(date)=>{handleRemove(i,date,true)}"
                      />
                    </div>
                    <div class="col-md-2">
                      <lenon-button
                        label="Edit"
                        tool-tip-text="Click to edit school breaks for this term"
                        icon="EditIcon"
                        @onClick="showHolidayModal(i,true)"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="row align-items-center">
                    <div class="col-md-3">
                      <b-form-checkbox v-model="term.is_promotional_term">
                        Promotional
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <lenon-select
                        v-model="term.tag_id"
                        label="Select Tag"
                        :name="`tag.${i}`"
                        :show-lable="false"
                        :options="tags"
                        label-name="name"
                        value-name="id"
                        :rules="`${isTagRequired?'required':''}`"
                      />
                    </div>
                    <div class="col-md-4">
                      <lenon-date-picker
                        v-model="term.next_term_reopening_date"
                        label="Next Term Reopening Date"
                        :name="`next_term.${i}`"
                        :show-lable="false"
                        rules="required"
                      />
                    </div>
                    <div class="col-md-2 mt-1">
                      <lenon-dropdown
                        v-b-tooltip.hover="'Remove Term'"
                        icon="TrashIcon"
                      >
                        <b-dropdown-item
                          :disabled="academicYear.terms.length===1"
                          @click="removeTerm(i)"
                        >
                          Yes
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item>
                          No
                        </b-dropdown-item>
                      </lenon-dropdown>
                    </div>
                  </div>
                </div>
                <div
                  v-if="term.tag_id"
                  class="col-12"
                >
                  <div
                    class="row align-items-center"
                  >
                    <div class="col-md-10">
                      <lenon-multi-select
                        v-model="term.classes"
                        :options="classes"
                        label-name="name"
                        value-name="id"
                        label="Affected Classes"
                        rules="required"
                        @input="(e)=>{updateAllSelected(e,i)}"
                      />
                    </div>
                    <div class="col-2 mt-2">
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <b-form-checkbox
                          v-model="term.allClassesSelected"
                          @change="(selected)=>{toggleAllSelected(selected,i)}"
                        >
                          All
                        </b-form-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
              </b-row>
            </b-list-group-item>
          </Draggable>
        </Container>
        <div
          v-else
          class="list-group list-group-flush"
        >
          <div
            v-for="(term,i) in academicYear.terms"
            :key="`term-${i}`"
          >
            <b-list-group-item>
              <div class="mt-1">
                <strong>Term {{ i + 1 }}</strong>
              </div>
              <b-row>
                <div class="col-md-4">
                  <lenon-select
                    v-model="term.term_id"
                    :options="terms"
                    label-name="name"
                    value-name="id"
                    label="Name"
                    name="term"
                    rules="required"
                  />
                </div>
                <div class="col-md-4">
                  <lenon-date-picker
                    v-model="term.term_start_date"
                    label="Start Date"
                    :name="`term_start_date`"
                    rules="required"
                    :show-lable="false"
                  />
                </div>
                <div class="col-md-4">
                  <lenon-date-picker
                    v-model="term.vacation_date"
                    label="Vacation Date"
                    :name="`vacation.${i}`"
                    :show-lable="false"
                    rules="required"
                  />
                </div>
                <div class="col-12 mt-2">
                  <label>School Breaks</label>
                  <div class="row">
                    <div class="col-md-10">
                      <lenon-multi-select
                        v-model="term.midterms"
                        :options="term.midterms"
                        label-name="title"
                        placeholder="Break Title"
                        @remove="(date)=>{handleRemove(i,date,true)}"
                      />
                    </div>
                    <div class="col-md-2 mt-1">
                      <lenon-button
                        label="Edit"
                        tool-tip-text="Click to edit school breaks for this term"
                        icon="EditIcon"
                        @onClick="showHolidayModal(i,true)"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="row align-items-center">
                    <div class="col-md-3">
                      <b-form-checkbox v-model="term.is_promotional_term">
                        Promotional
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <lenon-select
                        v-model="term.tag_id"
                        label="Select Tag"
                        :name="`tag.${i}`"
                        :show-lable="false"
                        :options="tags"
                        label-name="name"
                        value-name="id"
                        :rules="`${isTagRequired?'required':''}`"
                      />
                    </div>
                    <div class="col-md-4">
                      <lenon-date-picker
                        v-model="term.next_term_reopening_date"
                        label="Next Term Reopening Date"
                        :name="`next_term.${i}`"
                        :show-lable="false"
                        rules="required"
                      />
                    </div>
                    <div class="col-md-2">
                      <lenon-dropdown
                        v-b-tooltip.hover="'Remove Term'"
                        icon="TrashIcon"
                      >
                        <b-dropdown-item
                          :disabled="academicYear.terms.length===1"
                          @click="removeTerm(i)"
                        >
                          Yes
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item>
                          No
                        </b-dropdown-item>
                      </lenon-dropdown>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-list-group-item>
          </div>
        </div>
        <div class="float-right mt-2">
          <lenon-button
            variant="outline-primary"
            icon="PlusIcon"
            label=""
            tool-tip-text="Add a term"
            class=""
            @onClick="addMoreTerms()"
          />
        </div>
        <template slot="modal-actions">
          <b-row class="float-right">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeAcademicYearModal()"
            />
            <lenon-button
              :label="updateAcademicYearMode?'Update':'Create'"
              :disabled="invalid"
              :loading="academicYearLoading"
              loading-text="Loading..."
              @onClick="setUpAcademicYear()"
            />
          </b-row>
        </template>
      </lenon-modal>
    </validation-observer>
    <lenon-modal
      :title="isMidterm?`Add Breaks`:`Add Holidays`"
      :show="holidaysModalOpened"
      size="sm"
      ok-text="Done"
      @onOk="handleOk()"
      @onClose="closeHolidayModal()"
    >
      <div
        v-for="(holiday,i) in holidays"
        :key="i"
        class="row align-items-center"
      >
        <div class="col-12">
          <lenon-input
            v-model="holiday.title"
            placeholder="Enter Title"
            label="Title"
            rules="required"
          />
        </div>
        <div
          v-if="isMidterm"
          class="col-12"
        >
          <div class="row align-items-center">
            <div class="col-12">
              <lenon-date-picker
                v-model="holiday.start_date"
                rules="required"
                label="Start Date"
              />
            </div>
            <div class="col-md-9">
              <lenon-date-picker
                v-model="holiday.end_date"
                rules="required"
                label="Resume Date"
              />
            </div>
            <div class="col-md-3 mt-1">
              <lenon-button
                variant="flat-danger"
                tool-tip-text="Remove Midterm"
                :icon-only="true"
                icon="TrashIcon"
                @onClick="removeHoliday(i)"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="col-12"
        >
          <div class="row align-items-center">
            <div class="col-md-9">
              <lenon-date-picker
                v-model="holiday.date"
                rules="required"
                label="Date"
              />
            </div>
            <div class="col-md-3 mt-1">
              <lenon-button
                variant="flat-danger"
                tool-tip-text="Remove Holiday"
                :icon-only="true"
                icon="TrashIcon"
                @onClick="removeHoliday(i)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="holidays.length===0"
        class="row"
      >
        <div class="col-12">
          <p class="text-center">
            Please add a {{ isMidterm ? 'break' : 'break' }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lenon-button
            class="float-right"
            :icon-only="true"
            icon="PlusIcon"
            @onClick="addHoliday()"
          />
        </div>
      </div>
    </lenon-modal>
  </div>
</template>

<script>
import {
  BRow, BFormCheckbox, BDropdownItem, BDropdownDivider, BListGroupItem, BLink, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { Container, Draggable } from 'vue-smooth-dnd'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
// eslint-disable-next-line import/extensions
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import { DELETE_ACADEMIC_YEAR_TERM_M, DELETE_ACADEMIC_YEAR_M, SETUP_ACADEMIC_YEAR_M } from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import { applyDrag } from '@/libs/dnd'
import LenonInput from '@/lenon/components/LenonInput'
import { ACADEMIC_YEARS_Q } from '@/graphql/queries'

export default {
  name: 'AcademicYearSetup',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    LenonInput,
    ErrorDisplay,
    LenonDropdown,
    LenonMultiSelect,
    LenonDatePicker,
    LenonSelect,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    BDropdownItem,
    BDropdownDivider,
    Container,
    Draggable,
    BListGroupItem,
    BLink,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentAyear: 'XXXX/XXXX',
      error: {},
      academicYearModalOpened: false,
      deletingAcademicYear: false,
      updateAcademicYearMode: false,
      academicYearLoading: false,
      holidays: [],
      holidaysModalOpened: false,
      isMidterm: false,
      selectedAcademicYearId: null,
      currentTermIndex: null,
      academicYear: {
        id: null,
        name: null,
        start_date: null,
        end_date: null,
        terms: [{
          id: null,
          term_id: null,
          term_start_date: null,
          term_end_date: null,
          vacation_date: null,
          next_term_reopening_date: null,
          is_promotional_term: false,
          tag_id: null,
          holidays: [],
          midterms: [],
          classes: [],
        }],
      },
    }
  },
  computed: {
    isTagRequired() {
      return this.academicYear.terms.some(t => !!t.tag_id)
    },
    selectedAy() {
      return this.academicYears.find(ay => +ay.id === +this.selectedAcademicYearId)
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    terms() {
      return this.$store.getters['termsClasses/schoolTerms']
    },
    tags() {
      return this.$store.getters['termsClasses/tags']
    },
    classes() {
      return this.$store.getters['termsClasses/academicYearClasses']
    },
    holidayType() {
      return this.isMidterm ? 'midterms' : 'holidays'
    },
    // holidays() {
    //   this.updatedHolidays
    //   if (this.academicYear.terms[this.currentTermIndex] && this.academicYear.terms[this.currentTermIndex][this.holidayType]) {
    //     return this.academicYear.terms[this.currentTermIndex][this.holidayType]
    //   }
    //   return []
    // }
  },
  watch: {
    modalOpened(opened) {
      this.academicYearModalOpened = opened
      // if (opened) {
      //   this.setCurrentAcademicYear(this.selectedAy)
      // }
    },
    // selectedAy(selectedAy) {
    //   this.setCurrentAcademicYear(selectedAy)
    // },
  },
  mounted() {
    this.generateAcademicYears()
  },
  methods: {
    deleteAcademicYear() {
      if (!this.selectedAy) {
        this.showInfo('Please select an Academic Year ')
        return
      }
      this.academicYearLoading = true
      this.$apollo.mutate({ mutation: DELETE_ACADEMIC_YEAR_M, variables: { id: this.selectedAcademicYearId } }).then(() => {
        this.academicYearLoading = false
        this.showSuccess('Deleted Successfully')
        this.getAcademicYears()
        // this.$store.commit('termsClasses/removeAcademicYear', this.selectedAcademicYearId)
        this.clearForm()
      }).catch(() => {
        this.academicYearLoading = false
      })
    },
    handleRemove(index, date, isMidterm) {
      if (isMidterm) {
        this.academicYear.terms[index].midterms = this.academicYear.terms[index].midterms.filter(m => m.date !== date)
      } else {
        this.academicYear.terms[index].holidays = this.academicYear.terms[index].holidays.filter(m => m.date !== date)
      }
    },
    handleOk() {
      this.academicYear.terms[this.currentTermIndex][this.holidayType] = this.holidays
      this.closeHolidayModal()
    },
    addHoliday() {
      if (this.isMidterm) {
        this.holidays.push({
          title: null,
          start_date: null,
          end_date: null,
        })
      } else {
        this.holidays.push({
          title: null,
          date: null,
        })
      }
    },
    removeHoliday(index) {
      this.holidays.splice(index, 1)
    },
    closeHolidayModal() {
      this.holidaysModalOpened = false
    },
    showHolidayModal(index, isMidterm) {
      this.isMidterm = isMidterm
      this.currentTermIndex = index
      if (!this.academicYear.terms[index][this.holidayType]) {
        this.academicYear.terms[index][this.holidayType] = []
      }
      this.holidays = [...this.academicYear.terms[index][this.holidayType]]
      this.holidaysModalOpened = true
    },
    populateAcademicYearForm(ay) {
      if (this.selectedAy) {
        this.updateAcademicYearMode = true
        this.academicYear = {
          ...this.selectedAy,
          terms: this.selectedAy.terms.map(t => ({
            ...t,
            vacation_date: t.vacation_date,
            next_term_reopening_date: t.next_term_reopening_date,
            tag_id: t.tag_id,
            is_promotional_term: t.is_promotional_term,
            holidays: t.holidays || [],
            midterms: t.midterms || [],
            allClassesSelected: t.classes.length === this.classes.length,
          })),
        }
      } else {
        this.clearForm()
      }
    },
    clearForm() {
      this.academicYear = {
        id: null,
        name: null,
        start_date: null,
        end_date: null,
        terms: [{
          id: null,
          term_id: null,
          term_start_date: null,
          term_end_date: null,
          vacation_date: null,
          next_term_reopening_date: null,
          is_promotional_term: false,
          tag_id: null,
          holidays: [],
          midterms: [],
          classes: [],
        }],
      }
      this.updateAcademicYearMode = false
      this.selectedAcademicYearId = null
      this.$refs.academicYearForm.reset()
    },
    onDrop(dropResult) {
      this.academicYear.terms = applyDrag(this.academicYear.terms, dropResult)
    },
    generateAcademicYears() {
      const thisYear = new Date().getFullYear()
      // this.academicYears.push(`${thisYear}/${thisYear + 1}`)
      this.currentAyear = `${thisYear}/${thisYear + 1}`
    },
    updateAllSelected(selected, index) {
      setTimeout(() => {
        this.academicYear.terms[index].allClassesSelected = this.classes.length === selected.length
      }, 200)
    },
    toggleAllSelected(selected, index) {
      if (selected) {
        this.academicYear.terms[index].classes = this.classes
      } else {
        this.academicYear.terms[index].classes = []
      }
    },
    addMoreTerms() {
      this.academicYear.terms.push({
        id: null,
        term_id: null,
        term_start_date: null,
        term_end_date: null,
        vacation_date: null,
        next_term_reopening_date: null,
        is_promotional_term: false,
        tag_id: null,
        holidays: [],
        midterms: [],
        classes: [],
      })
    },
    removeTerm(i) {
      const term = this.academicYear.terms[i]
      if (term.id) {
        this.academicYearLoading = true
        this.$apollo.mutate({
          mutation: DELETE_ACADEMIC_YEAR_TERM_M,
          variables: { id: term.id },
        })
          .then(res => {
            this.academicYearLoading = false
            this.academicYear.terms.splice(i, 1)
            this.showSuccess('Deleted Successfully')
            this.$store.commit('termsClasses/removeAcademicYearTerm',
              {
                id: this.academicYear.id,
                academicYearTermId: term.id,
              })
          })
          .catch(err => {
            this.academicYearLoading = false
            console.log(err)
            this.showError('Something went wrong, please try again')
          })
      } else {
        this.academicYear.terms.splice(i, 1)
      }
    },
    setUpAcademicYear() {
      this.error = {}
      this.academicYearLoading = true
      const academicYear = {
        ...this.academicYear,
        terms: this.academicYear.terms.map(
          t => ({
            id: t.id,
            term_id: t.term_id,
            term_start_date: t.term_start_date,
            term_end_date: t.term_end_date,
            vacation_date: t.vacation_date,
            next_term_reopening_date: t.next_term_reopening_date,
            tag_id: t.tag_id || null,
            is_promotional_term: t.is_promotional_term || false,
            holidays: t.holidays,
            midterms: t.midterms,
            classes: t.tag_id == null ? [] : t.classes.map(c => c.id),
          }),
        ),
      }
      delete academicYear.completed
      delete academicYear.current
      delete academicYear.formatted_start_date
      delete academicYear.formatted_end_date
      delete academicYear.formatted_vacation_date
      delete academicYear.formatted_next_term_reopening_date
      delete academicYear._showDetails
      delete academicYear.most_recent

      this.$apollo.mutate({
        mutation: SETUP_ACADEMIC_YEAR_M,
        variables: { academic_year: academicYear },
      })
        .then(res => {
          this.academicYearLoading = false
          this.getAcademicYears()
          // this.$store.commit('termsClasses/updateAcademicYear', res.data.setupAcademicYear)
          this.showSuccess('Saved Successfully')
        })
        .catch(err => {
          this.academicYearLoading = false
          logData(err)
          this.error = err
          this.showError('Something went wrong, please try again')
        })
    },
    closeAcademicYearModal() {
      this.academicYearModalOpened = false
      this.$emit('modalClosed')
    },
    showAcademicYearModal() {
      this.academicYearModalOpened = true
    },
    updateAcademicYear() {
      this.academicYearLoading = true
    },
    getAcademicYears() {
      this.$apollo.query({ query: ACADEMIC_YEARS_Q })
        .then(res => {
          this.$store.commit('termsClasses/setAcademicYears', res.data)
          this.$store.commit('termsClasses/setTerms', res.data)
        })
    },
  },
}
</script>

<style scss>
#lenon_draggable{
  margin-bottom: 10px;
  padding-bottom: 20px;
  background-color: transparent !important;
}
.list-group-item:active{
  background-color: #161d31 !important;
}
</style>
